import React from 'react';
import {Auth0Provider} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

interface IProps {
	children: any
}

function AuthProviderWithRedirect({children}: IProps) {
	const navigate = useNavigate();

	const handleRedirect = (appState: any) => {
		navigate(appState?.returnTo ?? window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
			clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
			onRedirectCallback={handleRedirect}
			authorizationParams={{
				audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
				redirect_uri: window.location.origin,
				scope: "openid profile email",
			}}>
			{children}
		</Auth0Provider>
	);
}

export default AuthProviderWithRedirect;
