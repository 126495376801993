import React from 'react';
import {IBoardInvitation} from "../../ModelContracts";
import {Button, Card, Stack} from "react-bootstrap";

import './InvitationCard.scss';
import '../../styles/Card.css';
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";

interface IProps {
	invitation: IBoardInvitation
}

function InvitationCard({invitation}: IProps) {
	const {acceptBoardInvitation, rejectBoardInvitation} = useApi();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const acceptBoardInvitationCommand =
		useMutation(() => acceptBoardInvitation(invitation.boardId, invitation.id), {
			onSuccess: async () => {
				await queryClient.invalidateQueries('boards');
				await queryClient.invalidateQueries('board-invitations');
				navigate(`/boards/${invitation.boardId}`);
			}
		});
	const rejectBoardInvitationCommand =
		useMutation(() => rejectBoardInvitation(invitation.boardId, invitation.id), {
			onSuccess: async () => {
				await queryClient.invalidateQueries('board-invitations');
			}
		});

	const handleAccept = () => {
		acceptBoardInvitationCommand.mutate();
	};

	const handleReject = () => {
		rejectBoardInvitationCommand.mutate();
	};

	return (
		<Card role="listitem" className="invitation-card bank-card card m-2" aria-label={invitation.accountName}>
			<Card.Body className="d-flex p-0">
				<div className="position-relative d-flex flex-column align-items-between pt-2 pb-4 px-2">
					<Stack direction="vertical">
						<Card.Title className="mb-1"><h5 className="m-0">{invitation.accountName}</h5></Card.Title>
						<h6 className="fw-normal">{invitation.boardName} Board</h6>
					</Stack>
					<Stack direction="horizontal" className="flex-row-reverse justify-content-end gap-2">
						<Button variant="primary" onClick={handleAccept}>Accept</Button>
						<Button variant="outline-danger" onClick={handleReject}>Reject</Button>
					</Stack>
				</div>
			</Card.Body>
		</Card>
	);
}

export default InvitationCard;
