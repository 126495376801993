import React, {useState} from 'react';
import {IEvent, IPagedEventList} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {Alert} from "react-bootstrap";
import EventCard from "../EventCard/EventCard";
import {Link} from "react-router-dom";

import '../../styles/RecentEvents.css';

function RecentEvents() {
	const [events, setEvents] = useState<Array<IEvent>>([]);
	const [moreToLoad, setMoreToLoad] = useState<boolean>(false);
	const {getEvents} = useApi();
	useQuery<IPagedEventList>(
		['recent-events'],
		() => getEvents(),
		{
			onSuccess: (events: IPagedEventList) => {
				setEvents(events.items);
				setMoreToLoad(events.total > events.items.length);
			}
		}
	);

	return (
		<div className="recent-events">
			<h4 className="mb-3">Recent Events</h4>
			{
				!events.length &&
				<Alert variant="secondary">No activity yet.</Alert>
			}
			{
				events.map((event, i) => (
					<div key={`event-${i}`} className="mb-2">
						<EventCard event={event} maxLength={42} />
					</div>
				))
			}
			{
				moreToLoad &&
				<Link to={`/channel`} className="d-block text-center mt-3">
					<i className="bi bi-chevron-right"></i>
					View All
				</Link>
			}
		</div>
	);
}

export default RecentEvents;
