import React, {useContext, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Card, Button, Modal} from 'react-bootstrap';
import {ITask} from "../../ModelContracts";
import Labels from "../Labels/Labels";
import TaskStatus from "../TaskStatus/TaskStatus";
import UserList from "../UserList/UserList";
import {BoardContext} from "../../contexts/BoardContext";

import './TaskCard.scss';
import '../../styles/Card.css';
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";

interface IProps {
	task: ITask
}

function TaskCard({task}: IProps) {
	const {boardId} = useParams();
	const navigate = useNavigate();
	const {isAdmin} = useContext(BoardContext);
	const [isRemoving, setIsRemoving] = useState<boolean>(false);
	const {removeTask} = useApi();
	const queryClient = useQueryClient();
	const removeTaskCommand = useMutation(() => removeTask(task.id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
		}
	});

	const getPath = () => {
		const rootPath = boardId ? `/boards/${boardId}` : '';
		return `${rootPath}/tasks/${task.id}`;
	};

	const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		navigate(`/boards/${task.boardId}/tasks/${task.id}?edit=true`);
	};

	const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		navigate(`/boards/${task.boardId}/tasks/${task.id}`);
	};

	const handleViewCommentsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		navigate(`/boards/${task.boardId}/tasks/${task.id}#comments`);
	};

	const handleViewFilesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (!task.files.length) return;
		const file = task.files[0];
		navigate(`/tasks/${task.id}/files/${file.id}`);
	};

	const handleRemoveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setIsRemoving(true);
	};

	const handleCancelRemove = () => {
		setIsRemoving(false);
	};

	const handleConfirmRemove = async () => {
		await removeTaskCommand.mutate();
		setIsRemoving(false);
	};

	return (
		<>
			<Link to={getPath()}>
				<Card border="secondary" role="listitem" className="task-card bank-card card" aria-label={task.name}>
					<Card.Body className="d-flex p-0">
						<div className="position-relative flex-grow-1 pt-2 pb-4 px-2">
							<Card.Title><h5>{task.name}</h5></Card.Title>
							<TaskStatus task={task}/>
							{
								isAdmin &&
								<Labels labels={task.labels} maxCharacterCount={15}/>
							}
							<UserList users={task.assignees}/>
						</div>
						<div className="task-options card-options d-flex flex-column-reverse p-2">
							<Button className="position-relative"
									variant="link"
									title="View Task Files"
									disabled={!task.files.length}
									onClick={handleViewFilesClick}>
								<i className="bi bi-file-earmark"></i>
								View Files
								<p className={`file-count count-preview ${task.files.length >= 10 ? 'high-count-preview' : ''}`}>
									{task.files.length >= 10 ? '9+' : task.files.length}
								</p>
							</Button>
							<Button className="position-relative"
									variant="link"
									title="View Task Comments"
									onClick={handleViewCommentsClick}>
								<i className="bi bi-chat"></i>
								View Comments
								<p className={`count-preview ${task.comments.length >= 100 ? 'high-count-preview' : ''}`}>
									{task.comments.length >= 100 ? '99+' : task.comments.length}
								</p>
							</Button>
							{
								isAdmin &&
								<Button variant="link" title="Edit Task" onClick={handleEditClick}>
									<i className="bi bi-pencil"></i>
									Edit
								</Button>
							}
							<Button variant="link" title="View Task" onClick={handleViewClick}>
								<i className="bi bi-binoculars"></i>
								View
							</Button>
							{
								isAdmin &&
								<Button variant="link" title="Remove Task" onClick={handleRemoveClick}>
									<i className="bi bi-trash"></i>
									Remove
								</Button>
							}
						</div>
					</Card.Body>
				</Card>
			</Link>
			<Modal show={isRemoving} role="dialog" aria-label="Remove Task" onHide={handleCancelRemove}>
				<Modal.Header closeButton>
					<Modal.Title role="heading">Remove Task</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to remove the task?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="link" onClick={handleCancelRemove}>Cancel</Button>
					<Button variant="primary" onClick={handleConfirmRemove}>Remove</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TaskCard;
