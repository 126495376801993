import React from "react";
import {Route, Routes} from "react-router-dom";
import App from "./App";
import Account from "./pages/Account/Account";
import Admin from "./pages/Admin/Admin";
import Board from "./pages/Board/Board";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Template from "./pages/Template/Template";
import Templates from "./pages/Templates/Templates";
import Task from "./pages/Task/Task";
import useAppUser from "./hooks/useAppUser/useAppUser";
import Setup from "./pages/Setup/Setup";
import TaskFile from "./pages/TaskFile/TaskFile";
import Invitations from "./pages/Invitations/Invitations";
import Plan from "./pages/Plan/Plan";

function Locations() {
	const appUser = useAppUser();

	return (
		<Routes>
			<Route path="/" element={<App />}>
				<Route index element={<Home />} />
				<Route path="/channel" element={<Home showChannel={true} />} />
				{appUser.isAdmin && <Route path="/admin" element={<Admin />} />}
				<Route path="/account" element={<Account />} />
				<Route path="/boards/:boardId" element={<Board />} />
				<Route path="/boards/:boardId/channel" element={<Board showChannel={true} />} />
				<Route path="/boards/:boardId/tasks/:taskId" element={<Board />} />
				<Route path="/invitations" element={<Invitations />} />
				<Route path="/plan" element={<Plan />} />
				<Route path="/templates/:templateId" element={<Template />} />
				<Route path="/templates" element={<Templates />} />
				<Route path="/tasks/:taskId" element={<Task />} />
				<Route path="/tasks/:taskId/files/:fileId" element={<TaskFile />} />
			</Route>
			<Route path="/setup" element={<Setup />} />
			<Route path="/login" element={<Login />} />
		</Routes>
	)
}

export default Locations;
