import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useAuth0} from "@auth0/auth0-react";
import {
	IAccountPlan, IAvailablePlanAmount, IBoard,
	ICompleteSignupModel,
	ICreateAccountModel,
	ICreateCustomerModel,
	ICreateTaskModel,
	ICreateTemplateModel,
	ICreateUserModel,
	IPagedEventList,
	IPlanModel,
	ISetPlanResult,
	ISubscription,
} from "../../ModelContracts";

dayjs.extend(utc);

function useApi() {
	const baseURL = import.meta.env.VITE_API_URL;
	const {getAccessTokenSilently} = useAuth0();

	const client = axios.create({
		baseURL
	});

	const callApi = async (
		url: string,
		method: Method,
		body?: Object,
		mapResponse?: { (response: AxiosResponse): any },
		contentType: string = 'application/json') => {
		const getToken = async () => {
			try {
				return await getAccessTokenSilently();
			} catch (error) {
				return null;
			}
		};

		const token = await getToken();
		const config: AxiosRequestConfig = {
			url,
			method,
			headers: {
				'Content-Type': contentType,
				'Authorization': `Bearer ${token}`
			},
			maxRedirects: 0
		};
		if (body) config.data = body;
		const response = await client.request(config);
		if (mapResponse) return mapResponse(response);
		return response.data;
	};

	const mapPagedList = (response: AxiosResponse): IPagedEventList => {
		const items = response.data;
		const total = Number(response.headers['x-pagination-total']);
		return {
			items,
			total
		}
	};

	const acceptBoardInvitation = async (boardId: string, invitationId: string) =>
		callApi(`/boards/${boardId}/accepted-invitations/${invitationId}`, 'post');
	const addBoardMember = async (id: string, userId: string) =>
		callApi(`/boards/${id}/members`, 'post', userId);
	const addTaskFiles = async (id: string, files: Array<File>) => {
		const formData = new FormData();
		files.forEach(f => formData.append('files', f));
		await callApi(`/tasks/${id}/files`,
			'post',
			formData,
			undefined,
			'multipart/form-data');
	}
	const addTemplateFiles = async (id: string, files: Array<File>) => {
		const formData = new FormData();
		files.forEach(f => formData.append('files', f));
		await callApi(`/templates/${id}/files`,
			'post',
			formData,
			undefined,
			'multipart/form-data');
	}
	const archiveBoard = async (id: string) : Promise<IBoard> => callApi(`/boards/${id}/archived`, 'put');
	const assignTask = async (id: string, userId: string) =>
		callApi(`/tasks/${id}/assignees/${userId}`, 'put');
	const clearTaskDueDate = async (id: string) => callApi(`/tasks/${id}/due-date`, 'delete');
	const completeSignup = async (model: ICompleteSignupModel) => callApi('/signups', 'post', model);
	const createAccount = async (account: ICreateAccountModel) => callApi('/accounts', 'post', account);
	const createCustomer = async (customer: ICreateCustomerModel) => callApi('/customers', 'post', customer);
	const createTask = async (boardId: string, task: ICreateTaskModel) =>
		callApi(`/boards/${boardId}/tasks`, 'post', task);
	const createTaskWithFiles = async (boardId: string, task: ICreateTaskModel, files: Array<File>) => {
		const formData = new FormData();
		formData.append('name', task.name);
		formData.append('description', task.description);
		formData.append('hasTemplate', JSON.stringify(task.hasTemplate));
		if (task.dueDate) formData.append('dueDate', task.dueDate);
		task.assignees.forEach(a => formData.append('assignees', a));
		task.labels.forEach(l => formData.append('labels', l));
		files.forEach(f => formData.append('files', f));
		return await callApi(`/boards/${boardId}/tasks`,
			'post',
			formData,
			undefined,
			'multipart/form-data');
	};
	const createTaskComment = async (taskId: string, text: string) =>
		callApi(`/tasks/${taskId}/comments`, 'post', text);
	const createTemplate = async (template: ICreateTemplateModel) => callApi('/templates', 'post', template);
	const createTemplateWithFiles = async (template: ICreateTemplateModel, files: Array<File>) => {
		const formData = new FormData();
		formData.append('name', template.name);
		formData.append('description', template.description);
		template.labels.forEach(l => formData.append('labels', l));
		files.forEach(f => formData.append('files', f));
		return await callApi(`/templates`,
			'post',
			formData,
			undefined,
			'multipart/form-data');
	};
	const createUser = async (user: ICreateUserModel) => callApi('/users', 'post', user);
	const deleteUser = async (id: string) => callApi(`/users/${id}`, 'delete');
	const editTaskComment = async (taskId: string, commentId: string, text: string) =>
		callApi(`/tasks/${taskId}/comments/${commentId}`, 'put', text);
	const getAccountById = async (id: string) => callApi(`/accounts/${id}`, 'get');
	const getAccountReport = async () => callApi('/reports/account', 'get');
	const getAccounts = async () => callApi('/accounts', 'get');
	const getAvailableOnboardings = async () : Promise<IAvailablePlanAmount> =>
		callApi('/available-onboardings', 'get');
	const getAvailableUsers = async () : Promise<IAvailablePlanAmount> =>
		callApi('/available-users', 'get');
	const getBillingUrl = async () => callApi('/plans/billing', 'get');
	const getBoardById = async (id: string) => callApi(`/boards/${id}`, 'get');
	const getBoards = async () => callApi('/boards', 'get');
	const getBoardInvitations = async () => callApi('/board-invitations', 'get');
	const getBoardEvents = async (boardId: string, take: number = 4, skip: number = 0) => {
		const params = new URLSearchParams({
			skip: skip.toString(),
			take: take.toString()
		});
		return callApi(`/boards/${boardId}/events?${params.toString()}`, 'get', undefined, mapPagedList);
	}
	const getCurrentPlan = async (): Promise<IAccountPlan> => {
		return callApi(`/plans/current`, 'get');
	};
	const getEvents = async (take: number = 5, skip: number = 0) => {
		const params = new URLSearchParams({
			skip: skip.toString(),
			take: take.toString()
		});
		return callApi(`/events?${params.toString()}`, 'get', undefined, mapPagedList);
	}
	const getFile = async (path: string) => callApi(path, 'get');
	const getLabels = async (search?: string, startsWith?: string, take?: number, excluding?: Array<string>) => {
		const params = new URLSearchParams({
			search: search ?? '',
			startsWith: startsWith ?? '',
			take: take?.toString() ?? ''
		});
		if (excluding) excluding.forEach(e => params.append('excluding', e));
		const url = `/labels?${params.toString()}`;
		return callApi(url, 'get');
	};
	const getProposedPlanSubscription = async (plan: IPlanModel): Promise<ISubscription> =>
		callApi(`/plans/proposed/subscription?onboardings=${plan.onboardings}&users=${plan.users}`, 'get');
	const getTask = async (id: string) => callApi(`/tasks/${id}`, 'get');
	const getTemplate = async (id: string) => callApi(`/templates/${id}`, 'get');
	const getTemplates = async () => callApi('/templates', 'get');
	const getUsers = async (searchTerm?: string) => {
		let url = '/users';
		if (searchTerm) url += `?search=${searchTerm}`;
		return callApi(url, 'get');
	}
	const impersonate = async (accountId: string) =>
		callApi(`/impersonations/${accountId}`, 'put');
	const inviteBoardMember = async (boardId: string, email: string) =>
		callApi(`/boards/${boardId}/invitations`, 'post', email);
	const moveTask = async (boardId: string, taskId: string, targetListId: string, index: number) =>
		callApi(`/boards/${boardId}/tasks/${taskId}/to/${targetListId}/position/${index}`, 'put');
	const reactivateBoard = async (boardId: string) : Promise<IBoard> =>
		callApi(`/boards/${boardId}/archived`, 'delete');
	const rejectBoardInvitation = async (boardId: string, invitationId: string) =>
		callApi(`/boards/${boardId}/invitations/${invitationId}`, 'delete');
	const removeBoardMember = async (boardId: string, userId: string) =>
		callApi(`/boards/${boardId}/members/${userId}`, 'delete');
	const removeTask = async (taskId: string) => callApi(`/tasks/${taskId}`, 'delete');
	const removeTaskComment = async (taskId: string, commentId: string) =>
		callApi(`/tasks/${taskId}/comments/${commentId}`, 'delete');
	const removeTaskFile = async (taskId: string, fileId: string) =>
		callApi(`/tasks/${taskId}/files/${fileId}`, 'delete');
	const removeTemplate = async (templateId: string) =>
		callApi(`/templates/${templateId}`, 'delete');
	const removeTemplateFile = async (templateId: string, fileId: string) =>
		callApi(`/templates/${templateId}/files/${fileId}`, 'delete');
	const renameTask = async (id: string, name: string) => callApi(`/tasks/${id}/name`, 'put', name);
	const renameTemplate = async (id: string, name: string) => callApi(`/templates/${id}/name`, 'put', name);
	const setPlan = async (plan: IPlanModel) : Promise<ISetPlanResult> =>
		callApi('/plans/current', 'put', plan);
	const setTaskDescription = async (id: string, description: string) =>
		callApi(`/tasks/${id}/description`, 'put', description);
	const setTaskDueDate = async (id: string, date: string | Date) => {
		const value = dayjs(date).utc(true).toISOString();
		return callApi(`/tasks/${id}/due-date`, 'put', value);
	};
	const setTaskLabels = async (id: string, labels: Array<string>) =>
		callApi(`/tasks/${id}/labels`, 'put', labels);
	const setTemplateDescription = async (id: string, description: string) =>
		callApi(`/templates/${id}/description`, 'put', description);
	const setTemplateLabels = async (id: string, labels: Array<string>) =>
		callApi(`/templates/${id}/labels`, 'put', labels);
	const unassignTask = async (id: string, userId: string) =>
		callApi(`/tasks/${id}/assignees/${userId}`, 'delete');
	const unimpersonate = async () => callApi('/impersonations', 'delete');

	return {
		acceptBoardInvitation,
		addBoardMember,
		addTaskFiles,
		addTemplateFiles,
		archiveBoard,
		assignTask,
		clearTaskDueDate,
		completeSignup,
		createAccount,
		createCustomer,
		createTask,
		createTaskComment,
		createTaskWithFiles,
		createTemplate,
		createTemplateWithFiles,
		createUser,
		deleteUser,
		editTaskComment,
		getAccountById,
		getAccountReport,
		getAccounts,
		getAvailableOnboardings,
		getAvailableUsers,
		getBillingUrl,
		getBoardById,
		getBoards,
		getBoardEvents,
		getBoardInvitations,
		getCurrentPlan,
		getEvents,
		getFile,
		getLabels,
		getProposedPlanSubscription,
		getTask,
		getTemplate,
		getTemplates,
		getUsers,
		impersonate,
		inviteBoardMember,
		moveTask,
		reactivateBoard,
		rejectBoardInvitation,
		removeBoardMember,
		removeTask,
		removeTaskComment,
		removeTaskFile,
		removeTemplate,
		removeTemplateFile,
		renameTask,
		renameTemplate,
		setPlan,
		setTaskDescription,
		setTaskDueDate,
		setTaskLabels,
		setTemplateDescription,
		setTemplateLabels,
		unassignTask,
		unimpersonate,
	};
}

export default useApi;
